<template>
  <div class="RoundedButton">
    <slot name="icon">Icon</slot>
  </div>
</template>

<script>
export default {
  name: "RoundedButton",
};
</script>

<style lang="scss" scoped>
.RoundedButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>