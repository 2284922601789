<template>
  <div class="ColorBar__Container">
    <section class="ColorBar">
      <div v-for="(swatch, index) in toolSwatches"
        :key="swatch.id">
        <div
          class="Swatch"
          :style="{
            background: `url(${require(`@/assets/img/textures/chalk_${index+1}.png`)})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }"
          :class="{ 'Swatch--active': swatch.isActive && ['chalk'].includes(activeTool.id) }"
          @click="onSwatchClick(swatch)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setActiveToolOnCanvas } from "@/utils";

export default {
  name: "ColorBar",
  inheritAttrs: true,
  computed: {
    ...mapGetters("ui", ["swatches", "activeTool","activeModal","activeSwatch",'tools']),
    ...mapGetters("canvas", ["canvas"]),
    toolSwatches: function () {
      // `this` points to the vm instance
      return this.swatches.filter(swatch => swatch.type === this.type);
    }
  },
  props: {
    type: {
      default: 'color',
      type: String
    }
  },
  mounted() {
    // Init color
    //this.setActiveSwatch(this.swatches[0]);
  },
  methods: {
    ...mapActions("ui", ["setActiveSwatch", "setActiveModal", "setActiveTool","setActiveGroup"]),
    ...mapActions("canvas", ["setBrushColor", "setBrush", "setCanvasDrawingMode"]),
    onSwatchClick(swatch) {
      this.setActiveGroup('default');
      this.setActiveTool(this.tools[0].id);
      this.setActiveSwatch(swatch);

      if (swatch.type === "color") {
        this.setBrushColor(swatch.code);
      }

      this.setBrushes(this.tools[0], swatch.code);
    },
    setBrushes(tool, brushColor) {
      const activeToolOnCanvas = setActiveToolOnCanvas(this.activeTool, this.canvas);
      this.setBrush(activeToolOnCanvas);

      if (tool.id !== "eraser") {
        this.setBrushColor(brushColor);
      }
      this.setCanvasDrawingMode(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.ColorBar {
  &__Container {
    display: flex;
    margin-top: -1.5vw;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__swatches {
    display: flex;
    padding: 0;
  }

  .Swatch {
    cursor: pointer;
    width: 2.24vw;
    height: 9.3vw;
    position: relative;
    transform: translateY(0);
    margin: 0;
    transition: all 0.2s ease-in;
    &--active {
      transition: all 0.3s ease-out;
      transform: translateY(-30%);
    }
  }
}
</style>