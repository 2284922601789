
import { fabric } from "fabric";

fabric.BaseBrush.prototype.convertToImg = function() {
    const pixelRatio = this.canvas.getRetinaScaling(),
        c = fabric.util.copyCanvasElement(this.canvas.upperCanvasEl),
        xy = fabric.util.trimCanvas(c),
        image = new fabric.Image(c);

    image.set({
        left:xy.x/pixelRatio,
        top:xy.y/pixelRatio,
        'scaleX':1/pixelRatio,
        'scaleY':1/pixelRatio,
        objectCaching: true
    }).setCoords();

    image.lockMovementX = true;
    image.lockMovementY = true;
    image.hasControls = false;
    image.selectable = false;
    image.evented = false;
    image.id = "chalk";

    // const filter = new fabric.Image.filters.Blur({
    //     blur: 0.02
    //   });
    // image.filters.push(filter);
    // image.applyFilters();

    this.canvas.add(image).clearContext(this.canvas.contextTop);
    this.canvas.clearContext(this.canvas.contextTop);
    this.canvas.requestRenderAll();
}

fabric.util.trimCanvas = function(canvas) {
    var ctx = canvas.getContext('2d', {
        desynchronized: true,
        willReadFrequently: true,
    }),
        w = canvas.width,
        h = canvas.height,
        pix = {x:[], y:[]}, n,
        imageData = ctx.getImageData(0,0,w,h),
        fn = function(a,b) { return a-b };

    for (var y = 0; y < h; y++) {
        for (var x = 0; x < w; x++) {
            if (imageData.data[((y * w + x) * 4)+3] > 0) {
                pix.x.push(x);
                pix.y.push(y);
            }
        }
    }
    pix.x.sort(fn);
    pix.y.sort(fn);
    n = pix.x.length-1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

    canvas.width = w;
    canvas.height = h;
    ctx.putImageData(cut, 0, 0);

    return {x:pix.x[0], y:pix.y[0]};
}

fabric.MarkerBrush = fabric.util.createClass(fabric.BaseBrush, {
    color: '#000',
    opacity: 1,
    width: 10,

    _lastPoint: null,
    _point: null,
    _size: 0,

    initialize: function(canvas, opt) {
        opt = opt || {};

        this.canvas = canvas;
        this.width = opt.width || canvas.freeDrawingBrush.width;
        this.color = opt.color || canvas.freeDrawingBrush.color;
        this.opacity = opt.opacity || canvas.contextTop.globalAlpha;
        this.canvas.contextTop.globalAlpha = this.opacity;
        this._point = new fabric.Point();

        this.canvas.contextTop.lineJoin = 'round';
        this.canvas.contextTop.lineCap = 'round';
    },

    _render: function(pointer) {
        var ctx;

        /*
        ctx = this.canvas.contextTop;
        var color = new fabric.Color(this.color);
        color.setAlpha(0.4+Math.random()*0.5);
        ctx.strokeStyle = color.toRgba(color);
        ctx.beginPath();
        ctx.moveTo(this._lastPoint.x, this._lastPoint.y);		
        ctx.lineTo(pointer.x, pointer.y);
        ctx.stroke();

        var length = Math.round(Math.sqrt(Math.pow(pointer.x-this._lastPoint.x,2)+Math.pow(pointer.y-this._lastPoint.y,2))/(5/this._size));
        var xUnit = (pointer.x-this._lastPoint.x)/length;
        var yUnit = (pointer.y-this._lastPoint.y)/length;

        for(var i=0; i<length; i++ ){
            var xCurrent = this._lastPoint.x+(i*xUnit);	
            var yCurrent = this._lastPoint.y+(i*yUnit);
            var xRandom = xCurrent+(Math.random()-1.25)*this._size*2.4;			
            var yRandom = yCurrent+(Math.random()-1.25)*this._size*2.4;
            ctx.clearRect( xRandom, yRandom, Math.random()*0.3+1, Math.random()+0.3);
        }
        */
        
        ctx = this.canvas.contextTop;
        var color = new fabric.Color(this.color);
        color.setAlpha(0.5+Math.random()*0.4);
        ctx.strokeStyle = color.toRgba(color);
        ctx.beginPath();
        ctx.moveTo(this._lastPoint.x, this._lastPoint.y);		
        ctx.lineTo(pointer.x, pointer.y);
        ctx.stroke();

        var length = Math.round(Math.sqrt(Math.pow(pointer.x-this._lastPoint.x,2)+Math.pow(pointer.y-this._lastPoint.y,2))/(5/this._size));
        var xUnit = (pointer.x-this._lastPoint.x)/length;
        var yUnit = (pointer.y-this._lastPoint.y)/length;

        for(var i=0; i<length; i++ ){
            var xCurrent = this._lastPoint.x+(i*xUnit);	
            var yCurrent = this._lastPoint.y+(i*yUnit);
            var xRandom = xCurrent+(Math.random()-0.5)*this._size*1.2;			
            var yRandom = yCurrent+(Math.random()-0.5)*this._size*1.2;
            ctx.clearRect( xRandom, yRandom, Math.random()*1+1, Math.random()+0.5);
        }

        this._lastPoint = new fabric.Point(pointer.x, pointer.y);
    },

    onMouseDown: function(pointer) {
        this._lastPoint = pointer;
        this.canvas.contextTop.strokeStyle = this.color;
        this._size = this.width;
        this.canvas.contextTop.lineWidth = this._size;
    },

    onMouseMove: function(pointer) {
        if (this.canvas._isCurrentlyDrawing) {
            this._render(pointer);
        }
    },

    onMouseUp: function() {
        this.canvas.contextTop.globalAlpha = this.opacity;
        this.canvas.contextTop.globalAlpha = 1;

        this.convertToImg();
    }
});