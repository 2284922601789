<template>
  <aside class="ToolsSidebar">
    <rounded-button
      v-for="tool in groupTools"
      :key="tool.id"
      class="Interface__RoundedButton"
      :class="{ 'InterfaceElement--active': tool.isActive }"
      @click.native="onToolButtonClick(tool)"
    >
      <template #icon>
        <img :src="require(`@/assets/img/ui/tools/${tool.id}.png`)" />
      </template>
    </rounded-button>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { setActiveToolOnCanvas} from "@/utils";

import RoundedButton from "@/components/RoundedButton";

export default {
  name: "ToolsSidebar",
  components: {
    RoundedButton,
  },
  props: {
    type: {
      default: 'main',
      type: String
    }
  },
  computed: {
    ...mapGetters("ui", ["tools", "activeTool", "activeSwatch", "swatches"]),
    ...mapGetters("canvas", ["canvas"]),
    groupTools: function() {
      return this.tools.filter(tool => tool.group === this.type && tool.id != 'chalk');
    }
  },
  mounted() {
    // Init active tool as pencil brush
    // this.setActiveToolOnCanvas(this.tools[0]);
  },
  methods: {
    ...mapActions("ui", ["setActiveTool", "setSwatches", "setActiveSwatch", "setActiveModal"]),
    ...mapActions("canvas", [
      "setCanvasDrawingMode",
      "setBrush",
      "setBrushColor",
    ]),
    onToolButtonClick(tool) {
      console.log("@TOOLBUTTONCLICK", JSON.stringify(tool));
      
      this.setActiveTool(tool.id);

      if (tool.type === "brush") {
        this.setBrushes(tool);
      }

      if(tool.type === "element") {
        this.setElements();
      }
    },
    setBrushes(tool) {
      const activeToolOnCanvas = setActiveToolOnCanvas(tool, this.canvas);
      this.setBrush(activeToolOnCanvas);
      this.setCanvasDrawingMode(true);
    },
    setElements(){
      this.setCanvasDrawingMode(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.Interface {
  &__RoundedButton {
    box-sizing: border-box;
    width: 10.5vw;
    height: 10.5vw;
    padding: 0;
    margin: 0 0 0 1vw;
    position: relative;
    transform: translateY(15%);
    transition: all 0.2s ease-out;
    &:after{
      position: absolute;
      z-index: -1;
      display: block;
      content: '';
      width: 8.5vw;
      height: 8.5vw;
      background-color: rgba(0,0,0,0.9);
      border-radius: 100%;
      transform: translateX(4%);
      box-shadow: 0 0 30px 15px rgba(0,0,0,1);
    }
  }
  &Element--active {
    transition: all 0.3s ease-out;
    border-width: 0 !important;
    transform: translateY(0%);

    &:after {
      box-shadow: 0 0 20px 20px rgba(0,0,0,1);
    }
  }
}

.ToolsSidebar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: -3vw 0 0 0;
}
</style>
