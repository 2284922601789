<template>
    <div 
        class="window window--opened"
    >
        <div class="window__main">
            <div class="window__content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DraggableWindow',
    inheritAttrs: true,
    props: {
        name: {
            default: '',
            type: String
        }
    },
    computed: {
        //...mapGetters("ui", ["activeGroup"]),
        isActive() {
            return true;//this.activeGroup && this.activeGroup.id === this.name;
        }
    },
    data() {
        return {
            //...mapActions("ui", ["setActiveGroup"]),
            expanded: false,
            initPos: null,
            shadowActive: false,
        }
    },
    mounted() {},
    watch: {
        
    },
    methods: {
        onShadow() {
            this.shadowActive = true;
        },
        offShadow() {
            this.shadowActive = false;
        },
        onDragEnd: function() {
            
            this.offShadow();
        },
        onDragStart: function(positionDiff, absolutePosition) {
            this.initPos = absolutePosition;
            this.onShadow();
        }
    }
}
</script>


<style lang="scss" scoped>
    .window {
        $classWindow: &;
        z-index: 11;
        position: static;
        &--opened {
            z-index: 12;
            padding: 0 1.09vw;
        }
        &__main {
            position: relative;  
        }
        &__content {
            position: relative;
            display: flex;
        }
    }
</style>